import React, { useContext, useEffect, useState } from "react"; // , { useImperativeHandle }
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";
import {
  FaShoppingCart,
  FaBell,
  // FaEllipsisV,
  FaSms,
  FaEnvelope,
  FaCogs,
  FaSignOutAlt,
  FaCaretDown,
  FaUserAlt,
  FaLock,
  FaTicketAlt,
  FaUsers,
  FaUserTie,
  FaUserTag,
  FaBuilding,
  FaMapMarkedAlt,
  FaUsersCog,
  FaImages,
} from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { HiMiniUserGroup } from "react-icons/hi2";
// import { connect, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { tokenSelect } from "../../redux/select/auth";
import {
  useGetAllNotificaions,
} from "../../hooks/useGetNotificaion";
import { useIsAuthState, useIsAuthDispatch } from "../../hooks/useAuth";
import { useBasket } from "../../context/basket";
import { logout } from "../../utils/auth/logout";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { Cookies } from "react-cookie";
import {
  apiLogout,
  handleChangeUserRole,
  updateBranchingUser,
} from "../../utils/navbar/navbar.utils";
import SimpleLoading from "../common/loading/simple_loading";
import { SocketContext } from "../../context/socket";
import { FaUserGear } from "react-icons/fa6";
const AdminNavbar = React.forwardRef((props, ref) => {
  const { chat, status } = useContext(SocketContext);
  const { setUserStatus, getStatus } = chat;
  const isOnline = status === "online";

  const cook = new Cookies();
  const [show, setShow] = React.useState(false);
  const drawerState = useDrawerState();
  const setDrawerState = useDrawerSetter();
  const basket = useBasket();
  const queryClient = useQueryClient();
  const ulrPage = useLocation();

  const updateBranchingUserQuery = useMutation(
    "updateBranchingUserQuery",
    updateBranchingUser,
    {
      onSuccess: () => {
        if (Number(ulrPage?.pathname.split("/").at(-1))) {
          history.replace("/admin");
          window.location.reload();
        } else {
          window.location.reload();
        }
        localStorage.setItem("setPage", 0);
        localStorage.setItem("setPageSize", 10);
      },
    }
  );
  const apiLogoutQuery = useMutation("apiLogoutQuery", apiLogout, {
    onSuccess: () => {
      setUserStatus("logout");
      logout(history, authSetter, auth);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
  const closeNotificationBox = () => {
    if (show) {
      setShow(false);
    }
  };
  ref.current = {
    closeNotificationBox,
  };
  const history = useHistory();
  const { isFetching, isLoading, isError, data, isSuccess } =
    useGetAllNotificaions();
  const handleChange = () => {
    setShow((prev) => !prev);
  };
  const auth = useIsAuthState();
  const authSetter = useIsAuthDispatch();
  const [roles, setRoules] = useState([]);
  const [complexes, setComplex] = useState([]);
  // const [selectedRole, setSelectedRole] = useState(() => auth?.user?.frk_role);
  useEffect(() => {
    setComplex(
      auth?.complex?.map((item) => ({
        value: item?.id,
        label: item?.business_street,
      }))
    );
  }, [auth]);

  useEffect(() => {
    setRoules(
      auth?.roles?.map((item) => ({ value: item?.id, label: item?.role }))
    );
  }, [auth]);
  // const showBussinessProfile = auth?.roles?.some(
  //   (role) => +role?.roleType === 2
  // );
  const [loading, setLoading] = useState(false);
  return (
    <nav
      className="navbar-section m-0 ml-3 p-2 d-flex flex-row justify-content-between "
    // style={{ position: "relative" }}
    >
      {(auth.ds_status === 6 || auth.ds_status === 5) && (
        <>
          <div
            onClick={() => {
              setDrawerState((prev) => !prev);
            }}
            className={`${drawerState ? "change" : ""
              } menu_icon-container  d-sm-none mt-1`}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <div className="d-none d-sm-flex align-items-center">
            {/* <img
              src="/images/hillzlogo.png"
              alt="hillzlogo"
              className="p-0 m-0"
              style={{ height: "240px", width: "360px" }}
              // loading="lazy"
            /> */}
            <div className="p-0 m-0 mx-0 d-flex align-items-center justify-content-center">
              <div
                className="p-0 m-0 vehicle_avatar-image"
                style={{
                  backgroundImage: `url(/images/hillz_big_logo.png)`,
                  height: "55px",
                  width: "55px",
                }}
              ></div>
            </div>
            <div className="p-0 m-0">
              <img
                src="/images/HILLZDEALER-typo.png"
                alt="hillzlogo"
                className="p-0 m-0"
                style={{ height: "100px", width: "auto" }}
              />
            </div>
          </div>

          {show && (
            <div>
              <div className="navbar-notfication-rectangle" />
              <div className="bg-white shadow p-2 pt-4 m-0 navbar-notficationbox-container">
                <div className="d-fitemlex flex-column navbar-notficationbox-content h-100">
                  {data &&
                    data?.notifications
                      ?.filter((item) => item.status === 1)
                      .map(
                        (item, index) =>
                          item.status === 1 &&
                          index < 8 && (
                            <div
                              key={`notification${index + 1}`}
                              className=" m-2 notfication_item d-flex flex-column "
                              style={{ borderBottom: "1px solid #eee" }}
                            >
                              <div className="d-flex flex-row">
                                {item.message_type === 1 ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <FaSms className="m-1" />
                                    <h6 className="m-1">Sms</h6>
                                  </div>
                                ) : item.message_type === 2 ? (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <FaEnvelope className="m-1" />
                                    <h6 className="m-1">E-mail</h6>
                                  </div>
                                ) : (
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <FaCogs className="m-1" />
                                    <h6 className="m-1">System</h6>
                                  </div>
                                )}
                              </div>
                              <Link
                                to={{
                                  pathname: `/admin/notfication/${item.id}`,
                                  state: item,
                                }}
                                key={String(item.id)}
                                className=" m-4 mt-2 mb-2 text-limit text-secondary "
                                style={{ wordBreak: "break-all" }}
                              >
                                {item.notification_content}
                              </Link>
                              <small
                                className="m-4 mt-2 mb-2"
                                style={{ fontSize: 13, color: "#495057" }}
                              >
                                {new Date(
                                  Date.now() -
                                  new Date(item.createdAt).getTime()
                                ).getHours() +
                                  " " +
                                  "hours ago"}
                              </small>
                            </div>
                          )
                      )}
                  {data?.seen === 0 && (
                    <small className="text-center">
                      You dont have any message
                    </small>
                  )}
                  <div
                    style={{ borderTop: "1px solid #495057" }}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Link
                      // to="/admin/notfication"
                      to={{
                        pathname: `/admin/notfication`,
                      }}
                      className="p-1 m-0 vehile_icon-style text-decoration-none"
                      style={{ color: "#495057" }}
                    >
                      <strong>See All Notifications</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex flex-row justify-content-center align-items-center ">
            {/* {loading ? (
              <SimpleLoading />
            ) : (

            )} */}

            {complexes && complexes?.length !== 0 && (
              <Dropdown className="text-center mx-1" autoclose="outside">
                <Dropdown.Toggle
                  variant="none"
                  className="dropdown p-0 m-0 btn btn-outline-none "
                >
                  <OverlayTrigger
                    placement="bottom"
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>Select Branching</Tooltip>}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="p-0 mx-1 user-photo-container">
                        <div className="d-none d-md-flex">
                          {
                            complexes?.find(
                              (item) => item?.value === auth?.dealershipInfo?.id
                            )?.label
                          }
                        </div>
                      </div>
                      <i>
                        <FaCaretDown
                          className="navbar_icon-2"
                          style={{ fontSize: "1rem" }}
                        />
                      </i>
                    </div>
                  </OverlayTrigger>
                </Dropdown.Toggle>

                <Dropdown.Menu className=" dropdown-item-style mt-2">
                  {complexes?.map((item, index) => (
                    <Dropdown.Item
                      disabled={item?.value === auth?.dealershipInfo?.id}
                      onClick={() => {
                        localStorage.setItem("setPage", 0);
                        updateBranchingUserQuery.mutate(item?.value);
                      }}
                      key={`navbar${index + 1}`}
                      className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                    >
                      <p className="dropdown-items-menu p-0 m-0 mx-2">
                        {item?.label}
                      </p>
                      <i
                        className="p-0 m-0 edit_icon-style"
                        style={{
                          color: "#E75437",
                          fontSize: "1.8rem",
                        }}
                      >
                        <FaMapMarkedAlt />
                      </i>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {loading ? (
              <SimpleLoading />
            ) : (
              roles?.length !== 0 && (
                <Dropdown className="text-center mx-1" autoclose="outside">
                  <Dropdown.Toggle
                    variant="none"
                    className="dropdown p-0 m-0 btn btn-outline-none "
                  >
                    <OverlayTrigger
                      placement="bottom"
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>User Role</Tooltip>}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="p-0 mx-1 user-photo-container">
                          <div className="d-none d-md-flex">
                            {
                              roles?.find(
                                (item) => item?.value === auth?.user?.frk_role
                              )?.label
                            }
                          </div>
                          <div className="d-flex d-md-none">
                            {+auth?.user?.frk_role === 2 ? (
                              <i className="p-0 m-0 edit_icon-style">
                                <FaUsers
                                  style={{
                                    fontSize: "1.5rem",
                                  }}
                                />
                              </i>
                            ) : +auth?.user?.frk_role === 3 ||
                              +auth?.user?.frk_role === 15 ||
                              +auth?.user?.frk_role === 19 || +auth?.user?.frk_role === 22 ? (
                              <i
                                className="p-0 m-0 edit_icon-style"
                                style={{
                                  color: "#FC6E20",
                                  fontSize: "1.5rem",
                                }}
                              >
                                <FaUserTie />
                              </i>
                            ) : +auth?.user?.frk_role === 14 ||
                              +auth?.user?.frk_role === 16 ||
                              +auth?.user?.frk_role === 18 ? (
                              <i
                                className="p-0 m-0 edit_icon-style"
                                style={{
                                  color: "#e73737",
                                  fontSize: "1.8rem",
                                }}
                              >
                                <HiMiniUserGroup />
                              </i>
                            ) :
                              +auth?.user?.frk_role === 5 ?
                                <i
                                  className="p-0 m-0 edit_icon-style"
                                  style={{
                                    color: "#E75437",
                                    fontSize: "1.8rem",
                                  }}
                                >
                                  <FaUserGear />
                                </i>
                                : (
                                  <i
                                    className="p-0 m-0 edit_icon-style"
                                    style={{
                                      color: "#E75437",
                                      fontSize: "1.8rem",
                                    }}
                                  >
                                    <FaUserTag />
                                  </i>
                                )}
                          </div>
                        </div>
                        <i>
                          <FaCaretDown
                            className="navbar_icon-2"
                            style={{ fontSize: "1rem" }}
                          />
                        </i>
                      </div>
                    </OverlayTrigger>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className=" dropdown-item-style mt-2">
                    {roles?.map((item, index) => (
                      <Dropdown.Item
                        disabled={item?.value === auth?.user?.frk_role}
                        onClick={() => {
                          handleChangeUserRole(
                            item?.value,
                            authSetter,
                            history,
                            setLoading
                          );
                        }}
                        key={`navbarRoles${index + 1}`}
                        className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                      >
                        <p className="dropdown-items-menu p-0 m-0 mx-2">
                          {item?.label}
                        </p>
                        {item?.value === 2 ? (
                          <i className="p-0 m-0 edit_icon-style">
                            <FaUsers
                              style={{
                                fontSize: "1.5rem",
                              }}
                            />
                          </i>
                        ) : item?.value === 3 || item?.value === 15 || item?.value === 19 ? (
                          <i
                            className="p-0 m-0 edit_icon-style"
                            style={{
                              color: "#FC6E20",
                              fontSize: "1.5rem",
                            }}
                          >
                            <FaUserTie />
                          </i>
                        ) : +auth?.user?.frk_role === 14 ||
                          +auth?.user?.frk_role === 16 ||
                          +auth?.user?.frk_role === 18 ? (
                          <i
                            className="p-0 m-0 edit_icon-style"
                            style={{
                              color: "#e73737",
                              fontSize: "1.8rem",
                            }}
                          >
                            <HiMiniUserGroup />
                          </i>
                        ) : item?.value === 17 ? (
                          <i className="p-0 m-0 edit_icon-style" >
                            <FaUsersCog
                              style={{
                                fontSize: "2rem", color: "#FC6E20",
                              }}
                            />
                          </i>
                        ) : item?.value === 5 ?
                          <i
                            className="p-0 m-0 edit_icon-style"
                            style={{
                              color: "#E75437",
                              fontSize: "1.8rem",
                            }}
                          >
                            <FaUserGear />
                          </i>
                          :
                          (
                            <i
                              className="p-0 m-0 edit_icon-style"
                              style={{
                                color: "#E75437",
                                fontSize: "1.8rem",
                              }}
                            >
                              <FaUserTag />
                            </i>
                          )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )
            )}
            <Dropdown className="text-center mx-1" autoclose="outside">
              <Dropdown.Toggle
                variant="none"
                className="dropdown p-0 m-0 btn btn-outline-none "
              >
                <OverlayTrigger
                  placement="bottom"
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip className="">Profile Setting</Tooltip>}
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => {
                      getStatus();
                    }}
                  >
                    <div className="p-0 mx-1 user-photo-container">
                      <div
                        className="user-photo p-0 m-0"
                        style={{
                          backgroundImage: `url("https://hillzimage.blob.core.windows.net${auth?.user?.avatar}")`,
                        }}
                      ></div>
                    </div>
                    <i>
                      <FaCaretDown
                        className="navbar_icon-2"
                        style={{ fontSize: "1rem" }}
                      />
                    </i>
                  </div>
                </OverlayTrigger>
              </Dropdown.Toggle>

              <Dropdown.Menu className=" dropdown-item-style mt-2">
                <Dropdown.Item
                  onClick={() => history.push("/admin/update-profile")}
                  className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                >
                  <p className="dropdown-items-menu p-0 m-0">Profile</p>
                  <FaUserAlt className="dropdown-item_icon " size={15} />
                </Dropdown.Item>
                {(+auth?.user?.frk_role === 3 ||
                  +auth?.user?.frk_role === 15 ||
                  +auth?.user?.frk_role === 19 ||
                  +auth?.user?.frk_role === 22
                ) && (
                    <Dropdown.Item
                      onClick={() =>
                        history.push("/admin/update-bussiness-profile")
                      }
                      className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                    >
                      <p className="dropdown-items-menu p-0 m-0">
                        Business Profile
                      </p>
                      <FaBuilding className="dropdown-item_icon " size={15} />
                    </Dropdown.Item>
                  )}
                {(auth?.dealershipInfo?.id === 1 ||
                  auth?.dealershipInfo?.id === 409 ||
                  auth?.dealershipInfo?.id === 250 ||
                  auth?.dealershipInfo?.id === 430) && (
                    <Dropdown.Item
                      onClick={() => history.push("/admin/promotion")}
                      className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                    >
                      <p className="dropdown-items-menu p-0 m-0">Promotion</p>
                      <RiAdvertisementFill
                        className="dropdown-item_icon "
                        size={15}
                      />
                    </Dropdown.Item>
                  )}
                <Dropdown.Item
                  onClick={() => history.push("/admin/change-password")}
                  className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                >
                  <p className="dropdown-items-menu p-0 m-0">Password</p>
                  <FaLock className="dropdown-item_icon" size={15} />
                </Dropdown.Item>

                {auth?.user?.frk_role === 3 && [1, 250].includes(auth?.dealershipInfo?.id) && (
                  <Dropdown.Item
                    onClick={() => history.push("/admin/gallery")}
                    className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                  >
                    <p className="dropdown-items-menu p-0 m-0">Gallery</p>
                    <FaImages className="dropdown-item_icon " size={15} />
                  </Dropdown.Item>
                )}
                {auth?.dealershipInfo?.frk_default_bos_format === 2 ? auth?.user?.frk_role !== 16 && auth?.user?.frk_role !== 18 && (
                  <Dropdown.Item
                    onClick={() => history.push("/admin/presets")}
                    className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                  >
                    <p className="dropdown-items-menu p-0 m-0">Presets</p>
                    <FaCogs className="dropdown-item_icon " size={15} />
                  </Dropdown.Item>
                ) : null}
                {/* {auth?.dealershipInfo?.id === 250 || auth?.dealershipInfo?.id === 1  ? */}
                <Dropdown.Item
                  onClick={() => {
                    const toggledStatus = isOnline ? "offline" : "online";
                    setUserStatus(toggledStatus);
                  }}
                  className="p-0 px-2 py-1 m-0 d-flex justify-content-between align-items-center"
                >
                  <p className="dropdown-items-menu p-0 m-0">
                    I'm {isOnline ? "online" : "offline"}
                  </p>

                  <div className="form-check form-switch">
                    <input
                      checked={isOnline}
                      className="form-check-input"
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Dropdown.Item>
                {/* : null} */}
              </Dropdown.Menu>
            </Dropdown>
            {/* </Link> */}
            <Link
              // to={basket.basketItem.length !== 0 && "/admin/pricing"}
              to={{
                pathname: basket.basketItem.length !== 0 && "/admin/pricing",
              }}
              className="btn "
              style={{
                position: "relative",
                boxShadow: "0px 0px 0px #ffffff",
              }}
            >
              <small
                className=" p-1 shadow rounded-circle d-flex align-items-center justify-content-center text-white"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 2,
                  width: 20,
                  height: 20,
                  backgroundColor: "#1978a5",
                }}
              >
                {basket?.basketItem.length}
              </small>
              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={<Tooltip className="">Cart</Tooltip>}
              >
                <FaShoppingCart size={24} className="navbar_icon-2" />
              </OverlayTrigger>
            </Link>
            <div className="p-0 m-0 mx-2">
              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={<Tooltip className="">Ticketing</Tooltip>}
              >
                <Link
                  to={{
                    pathname:
                      auth?.user?.frk_role === 3 || auth?.user?.frk_role === 15 ||
                        +auth?.user?.frk_role === 19 || +auth?.user?.frk_role === 22
                        ? "/admin/dealership-ticket"
                        : auth?.user?.frk_role === 6 ||
                          auth?.user?.frk_role === 14 ||
                          auth?.user?.frk_role === 16 ||
                          auth?.user?.frk_role === 18
                          ? "/admin/user-ticket"
                          : auth?.user?.frk_role === 5 || auth?.user?.frk_role === 17
                            ? "/admin/vehicle-managment"
                            : null,
                  }}
                >
                  <i>
                    <FaTicketAlt size={24} className="navbar_icon-2" />
                  </i>
                </Link>
              </OverlayTrigger>
            </div>
            <div
              className="btn"
              style={{ position: "relative" }}
              onClick={handleChange}
            >
              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={<Tooltip className="">Notification</Tooltip>}
              >
                <FaBell size={24} className="navbar_icon-2" />
              </OverlayTrigger>
              {data && data.notifications && data.seen !== 0 && (
                <small
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 2,
                    width: 20,
                    height: 20,
                    backgroundColor: "#1978a5",
                  }}
                  className=" p-1 shadow rounded-circle d-flex align-items-center justify-content-center text-white"
                >
                  {/* not seen notif */}
                  {!data || data.notifications.length === 0
                    ? ""
                    : data.notifications.length - data.seen > 99
                      ? +99
                      : data.notifications.length - data.seen}
                </small>
              )}
            </div>
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="">Sign out</Tooltip>}
            >
              <i className="px-1" style={{ cursor: "pointer" }}>
                <FaSignOutAlt
                  size={24}
                  className="navbar_icon-2"
                  onClick={() => {
                    apiLogoutQuery.mutate();
                  }}
                />
              </i>
            </OverlayTrigger>
          </div>
        </>
      )}
      {(auth.ds_status === 3 ||
        auth.ds_status === 4 ||
        auth.ds_status === 7 ||
        auth.ds_status === 8 ||
        auth.ds_status === 9) && (
          <>
            <div className="d-flex align-items-center">
              {/* <img
              src="/images/hillzlogo.png"
              alt="hillzlogo"
              className="p-0 m-0"
              style={{ height: "240px", width: "360px" }}
              // loading="lazy"
            /> */}
              <div className="p-0 m-0 mx-0 d-flex align-items-center justify-content-center">
                <div
                  className="p-0 m-0 vehicle_avatar-image"
                  style={{
                    backgroundImage: `url(/images/hillz_big_logo.png)`,
                    height: "55px",
                    width: "55px",
                  }}
                ></div>
              </div>
              <div className="p-0 m-0">
                <img
                  src="/images/HILLZDEALER-typo.png"
                  alt="hillzlogo"
                  className="p-0 m-0"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center ">
              {/* {loading ? (
              <SimpleLoading />
            ) : (

            )} */}

              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={<Tooltip className="">Sign out</Tooltip>}
              >
                <i className="px-1" style={{ cursor: "pointer" }}>
                  <FaSignOutAlt
                    size={24}
                    className="navbar_icon-2"
                    onClick={() => {
                      apiLogoutQuery.mutate();
                    }}
                  />
                </i>
              </OverlayTrigger>
            </div>
          </>
        )}
    </nav>
  );
});

export default AdminNavbar;
